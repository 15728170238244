import { SALEOR_REFRESH_TOKEN_NAME } from "~/constants/saleor";

export default defineNuxtRouteMiddleware(() => {
  const refreshTokenCookie = useCookie(SALEOR_REFRESH_TOKEN_NAME);

  const refreshToken = refreshTokenCookie.value;

  if (!refreshToken) {
    return navigateTo("/login");
  }

  const payload = parseJwt(refreshToken);

  if (!payload?.is_staff) {
    return navigateTo("/login");
  }
});
